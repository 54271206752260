import service from "@/http/request";

// 客户发送统计
export const getCustomerStatisticsList = (data) => {
  return service({
    url: "/api/getCustomerStatisticsList",
    method: "post",
    data
  });
};
// 错误码统计
export const getErrorCodeStatisticsList = (data) => {
  return service({
    url: "/api/getErrorCodeStatisticsList",
    method: "post",
    data
  });
};
// 查询客户发送统计
export const getSignatureStatisticsList = (data) => {
  return service({
    url: "/api/getSignatureStatisticsList",
    method: "post",
    data
  });
};
// 导出客户发送统计
export const exportCustomerStatisticsList = (data) => {
  return service({
    url: "/api/exportCustomerStatisticsList",
    method: "post",
    data,
    responseType: "blob",
    fileName: "客户发送统计"
  });
};
// 导出错误码统计
export const exportErrorCodeStatisticsList = (data) => {
  return service({
    url: "/api/exportErrorCodeStatisticsList",
    method: "post",
    data,
    responseType: "blob",
    fileName: "错误码统计"
  });
};
// 导出签名统计
export const exportSignatureStatisticsList = (data) => {
  return service({
    url: "/api/exportSignatureStatisticsList",
    method: "post",
    data,
    responseType: "blob",
    fileName: "签名统计"
  });
};